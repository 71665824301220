import { useState } from 'react'
import { useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from '../services'

const useSelectGoalCustomize = () => {
  const [loading, setIsLoading] = useState(false)
  const { changeLeadData } = useLead()

  const onSubmit = async ({ goal }) => {
    setIsLoading(true)

    analytics.track('buttonClick', {
      currentPage: 'formulario-macro-objetivo-outros',
      detail: 'click-step-objetivo-outros',
      customLayer: {
        description: goal
      }
    })

    const payload = {
      macro_goal_id: 9,
      content: goal
    }

    return services
      .putMacroGoal(payload)
      .then(({ next_step }) => {
        changeLeadData({ macro_goal: 9 })
        goTo(next_step)
      })
      .finally(() => setIsLoading(false))
  }

  const handlePreviousStep = () => {
    changeLeadData({
      previousStep: 'select-macro-goal'
    })
  }

  useEffect(() => {
    handlePreviousStep()
  }, [])

  return { onSubmit, loading }
}

export default useSelectGoalCustomize
